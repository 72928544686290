.header {
  display: grid;
  grid-template-columns: min-content auto 0px min-content min-content 50px 50px;  
  grid-template-rows: 80px;
  height: 80px;
  width: 100%;
  align-items: center;
  background-color: var(--color-white);
  color: var(--color-black);
  border-bottom: 1px solid var(--color-white);
  background-size: 100% auto;
}

.logo {
  height: 50%;
  padding-right: 20%;
  padding-left: 20%;
}

.headerSection {
  display: flex;
  align-items: center;
  height: 100%;
  width: 20%;
  color: var(--color-green);
  text-decoration: none;
  text-align: center;
  background-size: 100% auto;
}

.alignRight {
  justify-content: flex-end;
}

.bHomeMetamask {
  width: 50px;
  height: 50px;
}
.baconMetamask {
  width: 50px;
  height: 50px;
}

.metamaskPrompt {
  visibility: visible;
  padding-bottom: 2%;
  cursor: pointer;
  margin-top:-2px;
}

.headerBalance {
  min-width: 150px;
  font-size: 20px;
  color: var(--color-green);
  grid-column:4;
  justify-self: end;
}

.walletInfo {
  display: flex;
  align-items: center;
  border: 2px solid var(--color-purple);
  border-radius: 30px;
  height: 50%;
  margin: 10px;
  padding: 0% 5%;
  color: var(--color-purple);
  font-size: 20px;
  margin: 15px;
  grid-column:5;
  justify-self: end;
}

.walletLogo {
  height: 50%;
  margin-right: 5px;
}

.dropdownVisible {
  visibility: visible;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  color: #000000;
  cursor: pointer;
  right: 6%;
}

.dropdownInvisible {
  visibility: hidden;
  position: absolute;
  z-index: 1;
}

.sidebarHost{
  align-self: end;
}

@media (max-width: 1080px) {
  .header{
    grid-template-columns: min-content auto 0px min-content min-content 25px 50px;
  }

  .headerBalance {
    font-size: 16px;
    min-width: 75px;
  }
  .pageTitle {
    display: none;
    visibility: hidden;
  }

  .headerBalance {
    margin: 5px;
  }
  .metamaskPrompt {
    display: none;
    visibility: hidden;
  }
  .dropdownVisible {
    right: 15%;
  }
}

@media (max-width: 565px) {
  .headerBalance {
    display: none;
    visibility: hidden;
  }
  .logo {
    height: 40%;
  }
}
